.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(250, 250, 250);
  height: 100vh;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 24px 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgb(210, 210, 210);
}

.login-container h2 {
  margin: 0;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 16px 0px 16px;
}

.login-form-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-page-bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.login-action {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  text-align: center;
}

.login-page-bottom p {
  font-size: 20px;
  margin: 0;
  padding-right: 8px;
}

.login-form button,
.login-page-bottom button {
  padding: 12px;
  font-size: 16px;
  color: white;
  outline: none;
  border: 1px solid black;
  background-color: cornflowerblue;
  cursor: pointer;
}

.login-form button:hover,
.login-page-bottom button:hover {
  background-color: royalblue;
}
