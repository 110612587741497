.tags-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px 40px 40px;
  text-align: center;
  gap: 12px;
}

.tags-header h2 {
  margin: 0;
}

.tags-header h3 {
  margin: 0;
}

.ask-button-container {
  width: 124px;
}

.tags-message {
  font-size: 20px;
  padding: 20px 40px 40px 40px;
}

.tags-table-container {
  display: flex;
  justify-content: center;
}

.tags-table {
  margin: 20px;
  width: 90%;
}

.tags-table-row {
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding-bottom: 40px;
}

.tags-table-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding: 8px;
  border: 1px dashed black;
  width: 160px;
  font-size: 20px;
}

.tags-table-cell a {
  color: cornflowerblue;
  text-decoration: underline;
  cursor: pointer;
}

.tags-table-cell a:hover {
  filter: brightness(80%);
}

.tags-table-cell p {
  margin: 0;
}

.empty-tags-table-cell {
  padding: 8px;
  border: 1px solid white;
  width: 160px;
}

.tags-buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-top: 8px;
}

.tags-buttons button,
.tags-modal button {
  padding: 4px;
  font-size: 16px;
  color: white;
  outline: none;
  border: 1px solid black;
  background-color: cornflowerblue;
  cursor: pointer;
}

.tags-buttons button:hover,
.tags-modal button:hover {
  background-color: royalblue;
}

.tags-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.tags-modal p {
  margin: 0;
  font-size: 16px;
}

.tags-modal-buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

@media (max-width: 768px) {
  .tags-table {
    width: 100%;
    gap: 8px;
  }

  .tags-table-row {
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    gap: 20px;
  }

  .tags-table-cell {
    width: 60%;
  }
}
