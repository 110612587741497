.welcome-page {
  background-color: rgb(250, 250, 250);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;
  padding: 24px 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgb(210, 210, 210);
}

.welcome-action p {
  font-size: 20px;
  margin: 0;
  padding: 8px;
}

.welcome-action button {
  padding: 12px;
  font-size: 16px;
  color: white;
  outline: none;
  border: 1px solid black;
  background-color: cornflowerblue;
  cursor: pointer;
}

.welcome-action button:hover {
  background-color: royalblue;
}
