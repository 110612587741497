.ask-question-button {
  padding: 12px;
  font-size: 16px;
  color: white;
  outline: none;
  border: 1px solid black;
  background-color: cornflowerblue;
  cursor: pointer;
}

.ask-question-button:hover {
  background-color: royalblue;
}
