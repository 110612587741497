.main-menu {
  border-right: 2px dashed black;
  flex: 1;
  position: sticky;
  top: 64px;
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu-list {
  list-style-type: none;
  margin: 0;
  padding: 16px;
  text-align: center;
}

.menu-item {
  color: cornflowerblue;
  background-color: white;
  font-size: 20px;
  padding: 16px;
  margin-bottom: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.menu-item:hover {
  filter: brightness(90%);
}

.active-menu-item {
  background-color: lightgray !important;
}

.menu-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 16px;
}

.menu-actions button {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  color: white;
  outline: none;
  border: 1px solid black;
  background-color: cornflowerblue;
  cursor: pointer;
}

.menu-actions button:hover {
  background-color: royalblue;
}

.menu-user {
  margin: 0;
  word-break: break-all;
}
