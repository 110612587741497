.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 16px 40px;
}

.pagination button {
  padding: 12px;
  font-size: 16px;
  color: white;
  outline: none;
  border: 1px solid black;
  background-color: cornflowerblue;
  cursor: pointer;
}

.pagination button:disabled {
  filter: brightness(80%);
  cursor: default;
}

.pagination button:not(:disabled):hover {
  background-color: royalblue;
}

.pagination span {
  font-size: 16px;
  text-align: center;
}
