.answer-card {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 20px 40px 40px 40px;
  border-bottom: 2px dashed black;
}

.answer-card p {
  margin: 0;
}

.answer-left {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.answer-voted {
  background-color: mediumblue !important;
}

.answer-vote {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
}

.answer-votes-num {
  text-align: center;
}

.answer-vote button {
  padding: 4px;
  font-size: 12px;
  color: white;
  outline: none;
  border-radius: 8px;
  border: none;
  background-color: cornflowerblue;
  cursor: pointer;
}

.answer-vote button:not(:disabled):hover {
  background-color: royalblue;
}

.answer-info {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
}

.answer-text-container {
  position: relative;
  min-height: 40px;
  padding-bottom: 32px;
}

.answer-edit-button {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 16px;
  padding: 4px;
  color: white;
  outline: none;
  border: 1px solid black;
  background-color: cornflowerblue;
  cursor: pointer;
}

.answer-edit-button:hover {
  background-color: royalblue;
}

.answer-metadata {
  font-size: 16px;
  max-width: 160px;
  min-width: 160px;
}

.answer-ans-by {
  color: green;
  word-break: break-all;
}

.answer-ans-date {
  color: gray;
  white-space: nowrap;
}

.answer-vote button:disabled {
  filter: brightness(80%);
  cursor: default;
}
