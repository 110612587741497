.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

/* Close button */
/* .modal-content button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
} */
/* 
.modal-content button.cancel {
  background-color: #ccc;
  margin-left: 10px;
} */

/* .modal-content input {
  width: 100%;
  padding: 8px;
  margin-top: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
} */
